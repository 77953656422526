
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';
import Script from 'next/script'

export default function SeoHead({
    site_name = "Xcede Technology Recruitment Specialists",
    page_name = "Xcede Technology Recruitment Specialists | Xcede",
    description = "Xcede is a global technology recruitment specialist able to source top talent across. Search and apply for permanent, interim and temporary jobs today",
    canonical_link = "https://www.xcede.com",
    og_image = "https://www.xcede.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMjVER2c9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--81b40635c282793c22b218cd6289b49f553a993b/xcede-logo.jpg",
    og_img_alt = "Xcede Technology Recruitment Specialists",
    noindex = false
}) {
    return (
        <>
            <SourceFlowHead metaObject={metaObject}>
                <title>{page_name}</title>
                <meta name="title" content={page_name} />
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical_link} key="canonical" />
            </SourceFlowHead>

            <NextSeo
                openGraph={{
                    url: canonical_link,
                    title: page_name,
                    site_name: site_name,
                    description: description,
                    images: [
                        {
                            url: og_image,
                            width: 1200,
                            height: 600,
                            alt: og_img_alt,
                            type: 'image/jpg',
                        }
                    ],
                }}
                noindex={noindex}
            />
        </>
    )
}
